import { useContext } from 'react';
import { GoogleMapContext } from '../GoogleMapProvider';

export function useGoogleMapConfig() {
  const contextValue = useContext(GoogleMapContext);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a GoogleMapProvider component');
  }

  return contextValue;
}
