const getMessages = (locale: string): any => {
  const availableTranslation = ['en'];

  const isAvailable = availableTranslation.includes(locale);

  if (isAvailable) {
    return require(`./${locale}.json`);
  }

  return require('./en.json');
};

export { getMessages };
