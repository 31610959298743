export const countryCodes = [
  { code: 'AF', value: 'afghanistan' },
  { code: 'AX', value: 'aland islands' },
  { code: 'AL', value: 'albania' },
  { code: 'DZ', value: 'algeria' },
  { code: 'AS', value: 'american samoa' },
  { code: 'AD', value: 'andorra' },
  { code: 'AO', value: 'angola' },
  { code: 'AI', value: 'anguilla' },
  { code: 'AQ', value: 'antarctica' },
  { code: 'AG', value: 'antigua and barbuda' },
  { code: 'AR', value: 'argentina' },
  { code: 'AM', value: 'armenia' },
  { code: 'AW', value: 'aruba' },
  { code: 'AU', value: 'australia' },
  { code: 'AT', value: 'austria' },
  { code: 'AZ', value: 'azerbaijan' },
  { code: 'BS', value: 'bahamas' },
  { code: 'BH', value: 'bahrain' },
  { code: 'BD', value: 'bangladesh' },
  { code: 'BB', value: 'barbados' },
  { code: 'BY', value: 'belarus' },
  { code: 'BE', value: 'belgium' },
  { code: 'BZ', value: 'belize' },
  { code: 'BJ', value: 'benin' },
  { code: 'BM', value: 'bermuda' },
  { code: 'BT', value: 'bhutan' },
  { code: 'BO', value: 'bolivia, plurinational state of' },
  { code: 'BA', value: 'bosnia and herzegovina' },
  { code: 'BW', value: 'botswana' },
  { code: 'BR', value: 'brazil' },
  { code: 'IO', value: 'british indian ocean territory' },
  { code: 'BN', value: 'brunei darussalam' },
  { code: 'BG', value: 'bulgaria' },
  { code: 'BF', value: 'burkina faso' },
  { code: 'BI', value: 'burundi' },
  { code: 'KH', value: 'cambodia' },
  { code: 'CM', value: 'cameroon' },
  { code: 'CA', value: 'canada' },
  { code: 'CV', value: 'cape verde' },
  { code: 'KY', value: 'cayman islands' },
  { code: 'CF', value: 'central african republic' },
  { code: 'TD', value: 'chad' },
  { code: 'CL', value: 'chile' },
  { code: 'CN', value: 'china' },
  { code: 'CX', value: 'christmas island' },
  { code: 'CC', value: 'cocos (keeling) islands' },
  { code: 'CO', value: 'colombia' },
  { code: 'KM', value: 'comoros' },
  { code: 'CG', value: 'congo' },
  { code: 'CD', value: 'congo, the democratic republic of the congo' },
  { code: 'CK', value: 'cook islands' },
  { code: 'CR', value: 'costa rica' },
  { code: 'CI', value: "cote d'ivoire" },
  { code: 'HR', value: 'croatia' },
  { code: 'CU', value: 'cuba' },
  { code: 'CY', value: 'cyprus' },
  { code: 'CZ', value: 'czech republic' },
  { code: 'DK', value: 'denmark' },
  { code: 'DJ', value: 'djibouti' },
  { code: 'DM', value: 'dominica' },
  { code: 'DO', value: 'dominican republic' },
  { code: 'EC', value: 'ecuador' },
  { code: 'EG', value: 'egypt' },
  { code: 'SV', value: 'el salvador' },
  { code: 'GQ', value: 'equatorial guinea' },
  { code: 'ER', value: 'eritrea' },
  { code: 'EE', value: 'estonia' },
  { code: 'ET', value: 'ethiopia' },
  { code: 'FK', value: 'falkland islands (malvinas)' },
  { code: 'FO', value: 'faroe islands' },
  { code: 'FJ', value: 'fiji' },
  { code: 'FI', value: 'finland' },
  { code: 'FR', value: 'france' },
  { code: 'GF', value: 'french guiana' },
  { code: 'PF', value: 'french polynesia' },
  { code: 'GA', value: 'gabon' },
  { code: 'GM', value: 'gambia' },
  { code: 'GE', value: 'georgia' },
  { code: 'DE', value: 'germany' },
  { code: 'GH', value: 'ghana' },
  { code: 'GI', value: 'gibraltar' },
  { code: 'GR', value: 'greece' },
  { code: 'GL', value: 'greenland' },
  { code: 'GD', value: 'grenada' },
  { code: 'GP', value: 'guadeloupe' },
  { code: 'GU', value: 'guam' },
  { code: 'GT', value: 'guatemala' },
  { code: 'GG', value: 'guernsey' },
  { code: 'GN', value: 'guinea' },
  { code: 'GW', value: 'guinea-bissau' },
  { code: 'GY', value: 'guyana' },
  { code: 'HT', value: 'haiti' },
  { code: 'VA', value: 'holy see (vatican city state)' },
  { code: 'HN', value: 'honduras' },
  { code: 'HK', value: 'hong kong' },
  { code: 'HU', value: 'hungary' },
  { code: 'IS', value: 'iceland' },
  { code: 'IN', value: 'india' },
  { code: 'ID', value: 'indonesia' },
  { code: 'IR', value: 'iran, islamic republic of persian gulf' },
  { code: 'IQ', value: 'iraq' },
  { code: 'IE', value: 'ireland' },
  { code: 'IM', value: 'isle of man' },
  { code: 'IL', value: 'israel' },
  { code: 'IT', value: 'italy' },
  { code: 'JM', value: 'jamaica' },
  { code: 'JP', value: 'japan' },
  { code: 'JE', value: 'jersey' },
  { code: 'JO', value: 'jordan' },
  { code: 'KZ', value: 'kazakhstan' },
  { code: 'KE', value: 'kenya' },
  { code: 'KI', value: 'kiribati' },
  { code: 'KP', value: "korea, democratic people's republic of korea" },
  { code: 'KR', value: 'korea, republic of south korea' },
  { code: 'KW', value: 'kuwait' },
  { code: 'KG', value: 'kyrgyzstan' },
  { code: 'LA', value: 'laos' },
  { code: 'LV', value: 'latvia' },
  { code: 'LB', value: 'lebanon' },
  { code: 'LS', value: 'lesotho' },
  { code: 'LR', value: 'liberia' },
  { code: 'LY', value: 'libyan arab jamahiriya' },
  { code: 'LI', value: 'liechtenstein' },
  { code: 'LT', value: 'lithuania' },
  { code: 'LU', value: 'luxembourg' },
  { code: 'MO', value: 'macao' },
  { code: 'MK', value: 'macedonia' },
  { code: 'MG', value: 'madagascar' },
  { code: 'MW', value: 'malawi' },
  { code: 'MY', value: 'malaysia' },
  { code: 'MV', value: 'maldives' },
  { code: 'ML', value: 'mali' },
  { code: 'MT', value: 'malta' },
  { code: 'MH', value: 'marshall islands' },
  { code: 'MQ', value: 'martinique' },
  { code: 'MR', value: 'mauritania' },
  { code: 'MU', value: 'mauritius' },
  { code: 'YT', value: 'mayotte' },
  { code: 'MX', value: 'mexico' },
  { code: 'FM', value: 'micronesia, federated states of micronesia' },
  { code: 'MD', value: 'moldova' },
  { code: 'MC', value: 'monaco' },
  { code: 'MN', value: 'mongolia' },
  { code: 'ME', value: 'montenegro' },
  { code: 'MS', value: 'montserrat' },
  { code: 'MA', value: 'morocco' },
  { code: 'MZ', value: 'mozambique' },
  { code: 'MM', value: 'myanmar' },
  { code: 'NA', value: 'namibia' },
  { code: 'NR', value: 'nauru' },
  { code: 'NP', value: 'nepal' },
  { code: 'NL', value: 'netherlands' },
  { code: 'AN', value: 'netherlands antilles' },
  { code: 'NC', value: 'new caledonia' },
  { code: 'NZ', value: 'new zealand' },
  { code: 'NI', value: 'nicaragua' },
  { code: 'NE', value: 'niger' },
  { code: 'NG', value: 'nigeria' },
  { code: 'NU', value: 'niue' },
  { code: 'NF', value: 'norfolk island' },
  { code: 'MP', value: 'northern mariana islands' },
  { code: 'NO', value: 'norway' },
  { code: 'OM', value: 'oman' },
  { code: 'PK', value: 'pakistan' },
  { code: 'PW', value: 'palau' },
  { code: 'PS', value: 'palestinian territory, occupied' },
  { code: 'PA', value: 'panama' },
  { code: 'PG', value: 'papua new guinea' },
  { code: 'PY', value: 'paraguay' },
  { code: 'PE', value: 'peru' },
  { code: 'PH', value: 'philippines' },
  { code: 'PN', value: 'pitcairn' },
  { code: 'PL', value: 'poland' },
  { code: 'PT', value: 'portugal' },
  { code: 'PR', value: 'puerto rico' },
  { code: 'QA', value: 'qatar' },
  { code: 'RO', value: 'romania' },
  { code: 'RU', value: 'russia' },
  { code: 'RW', value: 'rwanda' },
  { code: 'RE', value: 'reunion' },
  { code: 'BL', value: 'saint barthelemy' },
  { code: 'SH', value: 'saint helena, ascension and tristan da cunha' },
  { code: 'KN', value: 'saint kitts and nevis' },
  { code: 'LC', value: 'saint lucia' },
  { code: 'MF', value: 'saint martin' },
  { code: 'PM', value: 'saint pierre and miquelon' },
  { code: 'VC', value: 'saint vincent and the grenadines' },
  { code: 'WS', value: 'samoa' },
  { code: 'SM', value: 'san marino' },
  { code: 'ST', value: 'sao tome and principe' },
  { code: 'SA', value: 'saudi arabia' },
  { code: 'SN', value: 'senegal' },
  { code: 'RS', value: 'serbia' },
  { code: 'SC', value: 'seychelles' },
  { code: 'SL', value: 'sierra leone' },
  { code: 'SG', value: 'singapore' },
  { code: 'SK', value: 'slovakia' },
  { code: 'SI', value: 'slovenia' },
  { code: 'SB', value: 'solomon islands' },
  { code: 'SO', value: 'somalia' },
  { code: 'ZA', value: 'south africa' },
  { code: 'SS', value: 'south sudan' },
  { code: 'GS', value: 'south georgia and the south sandwich islands' },
  { code: 'ES', value: 'spain' },
  { code: 'LK', value: 'sri lanka' },
  { code: 'SD', value: 'sudan' },
  { code: 'SR', value: 'suriname' },
  { code: 'SJ', value: 'svalbard and jan mayen' },
  { code: 'SZ', value: 'swaziland' },
  { code: 'SE', value: 'sweden' },
  { code: 'CH', value: 'switzerland' },
  { code: 'SY', value: 'syrian arab republic' },
  { code: 'TW', value: 'taiwan' },
  { code: 'TJ', value: 'tajikistan' },
  { code: 'TZ', value: 'tanzania, united republic of tanzania' },
  { code: 'TH', value: 'thailand' },
  { code: 'TL', value: 'timor-leste' },
  { code: 'TG', value: 'togo' },
  { code: 'TK', value: 'tokelau' },
  { code: 'TO', value: 'tonga' },
  { code: 'TT', value: 'trinidad and tobago' },
  { code: 'TN', value: 'tunisia' },
  { code: 'TR', value: 'turkey' },
  { code: 'TM', value: 'turkmenistan' },
  { code: 'TC', value: 'turks and caicos islands' },
  { code: 'TV', value: 'tuvalu' },
  { code: 'UG', value: 'uganda' },
  { code: 'UA', value: 'ukraine' },
  { code: 'AE', value: 'united arab emirates' },
  { code: 'GB', value: 'united kingdom' },
  { code: 'US', value: 'united states' },
  { code: 'UY', value: 'uruguay' },
  { code: 'UZ', value: 'uzbekistan' },
  { code: 'VU', value: 'vanuatu' },
  { code: 'VE', value: 'venezuela, bolivarian republic of venezuela' },
  { code: 'VN', value: 'vietnam' },
  { code: 'VG', value: 'virgin islands, british' },
  { code: 'VI', value: 'virgin islands, u.s.' },
  { code: 'WF', value: 'wallis and futuna' },
  { code: 'YE', value: 'yemen' },
  { code: 'ZM', value: 'zambia' },
  { code: 'ZW', value: 'zimbabwe' },
];
