import { useContext, useEffect } from 'react';
import { GraphQLClientContext } from '../GraphQLClientProvider';

export function useGraphQLClient(authToken?: string) {
  const contextValue = useContext(GraphQLClientContext);

  useEffect(() => {
    if (authToken) {
      contextValue?.graphQLClient.setHeader('Authorization', `Bearer ${authToken}`);
    }
  }, [authToken, contextValue?.graphQLClient]);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a GraphQLClientProvider component');
  }

  return contextValue;
}
