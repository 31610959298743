import { useContext } from 'react';
import { ConfigurationContext } from '../ConfigProvider';

export function useConfig() {
  const contextValue = useContext(ConfigurationContext);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a ConfigProvider component');
  }

  return contextValue;
}
