export const REFRESH_TOKEN_KEY = '@RefreshToken';
export const ACCESS_TOKEN_KEY = '@AccessToken';
export const ID_TOKEN_KEY = '@IdToken';
export const AUTH0_USER_INFO_KEY = '@Auth0UserInfo';
export const REACT_QUERY_OFFLINE_CACHE = 'REACT_QUERY_OFFLINE_CACHE';
export const TASKS_TABLE_COLUMNS = 'TASKS_TABLE_COLUMNS';
export const TASKS_DISABLED_BOAT_RECORDS = 'TASKS_DISABLED_BOAT_RECORDS';
export const INVENTORY_TABLE_COLUMNS = 'INVENTORY_TABLE_COLUMNS';
export const INVENTORY_DISABLED_BOAT_RECORDS = 'INVENTORY_DISABLED_BOAT_RECORDS';
export const ALERTS_TABLE_COLUMNS = 'ALERTS_TABLE_COLUMNS';
export const ALERTS_DISABLED_BOAT_RECORDS = 'ALERTS_DISABLED_BOAT_RECORDS';
export const TRIPS_TABLE_COLUMNS = 'TRIPS_TABLE_COLUMNS';
export const ALL_LOGS_COLUMNS = 'ALL_LOGS_COLUMNS';
export const TRIPS_LOGS_TABLE_COLUMNS = 'TRIPS_LOGS_TABLE_COLUMNS';
export const BOATS_TABLE_COLUMNS = 'BOATS_TABLE_COLUMNS';
export const DISABLED_BOATS_KEY = 'DISABLED_BOATS_KEY';
export const COSTS_TABLE_COLUMNS = 'COSTS_TABLE_COLUMNS';
export const COSTS_DISABLED_BOAT_RECORDS = 'COSTS_DISABLED_BOAT_RECORDS';
export const FILES_TABLE_COLUMNS = 'FILES_TABLE_COLUMNS';
export const FILES_TABLE_VIEW = 'FILES_TABLE_VIEW';
export const ALERTS_TABLE_ORDER = 'ALERTS_TABLE_ORDER';
export const TASKS_TABLE_ORDER = 'TASKS_TABLE_ORDER';
export const BOATS_TABLE_ORDER = 'BOATS_TABLE_ORDER';
export const TRIPS_LOGS_TABLE_ORDER = 'TRIPS_LOGS_TABLE_ORDER';
export const TRIPS_DISABLED_BOAT_RECORDS = 'TRIPS_DISABLED_BOAT_RECORDS';
export const ALL_LOGS_TABLE_ORDER = 'ALL_LOGS_TABLE_ORDER';
export const ALL_LOGS_DISABLED_BOAT_RECORDS = 'ALL_LOGS_DISABLED_BOAT_RECORDS';

export const INVENTORY_TABLE_ORDER = 'INVENTORY_TABLE_ORDER';
export const TRIPS_TABLE_ORDER = 'TRIPS_TABLE_ORDER';
export const COSTS_TABLE_ORDER = 'COSTS_TABLE_ORDER';

export const MY_CHECKLIST_TABLE_VIEW = 'MY_CHECKLIST_TABLE_VIEW';
export const MY_CHECKLIST_TABLE_ORDER = 'MY_CHECKLIST_TABLE_ORDER';
export const MY_CHECKLIST_TABLE_COLUMNS = 'MY_CHECKLIST_TABLE_COLUMNS';
export const PUBLIC_CHECKLIST_TABLE_VIEW = 'PUBLIC_CHECKLIST_TABLE_VIEW';
export const PUBLIC_CHECKLIST_TABLE_ORDER = 'PUBLIC_CHECKLIST_TABLE_ORDER';
export const PUBLIC_CHECKLIST_TABLE_COLUMNS = 'PUBLIC_CHECKLIST_TABLE_COLUMNS';
export const CHECKLIST_AUTOSAVE_DISABLED = 'CHECKLIST_AUTOSAVE_DISABLED';

export const ANNOUNCEMENT_ALERTS_IDS = 'ANNOUNCEMENT_ALERTS_IDS';
