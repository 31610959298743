import { GraphQLClient } from 'graphql-request';
import { ConfigType } from 'providers';

export const getGraphQLClient = (config: ConfigType): GraphQLClient => {
  if (!config.mdcGraphQlUrl) {
    throw new Error(`⚠️ No API URL setup. 
    Make sure you have created appConfiguration file`);
  }

  return new GraphQLClient(config.mdcGraphQlUrl);
};
