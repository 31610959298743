import { useContext } from 'react';
import { AntNotificationContext } from '../AntNotificationProvider';

export function useAntNotification() {
  const contextValue = useContext(AntNotificationContext);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a AntNotificationProvider component');
  }

  return contextValue;
}
