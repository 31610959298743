import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import '@fontsource/inter/scss/mixins.scss';
import './variables.scss';
import './overwrites.scss';
import { ConfigProvider, ThemeConfig } from 'antd';

export type ThemeProps = {
  /**
   * Override the default light theme
   */
  lightTheme?: string;
  /**
   * Override the default dark theme
   */
  darkTheme?: string;
  /**
   * colors to override the default theme colors
   */
  colors?: string;
  /**
   * fonts to override the default theme fonts
   */
  fonts?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Theme = ({ children, lightTheme, darkTheme, colors, fonts, className }: ThemeProps) => {
  const token: ThemeConfig['token'] = {
    colorPrimary: '#1e00d5',
    colorSuccess: '#2DD881',
    colorError: '#EF3E31',
    colorWarning: '#FAAD14',
    colorLink: '#1e00d5',
    borderRadius: 4,
    fontFamily: fonts,
  };

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token,
        components: {
          Layout: {
            colorBgHeader: 'white',
          },
        },
      }}
    >
      <div className={classNames(className, colors, fonts, lightTheme, darkTheme)}>{children}</div>
    </ConfigProvider>
  );
};
