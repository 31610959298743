import { cmsGraphQLClient } from '@app/graphQLClient';
import { Auth0Provider } from '@auth0/auth0-react';
import { event, pageview } from '@marine/lib';
import { withPasswordProtect } from '@marine/next-password-protect';
import { Theme } from '@marine/theme';
import '@marine/theme/antd/antd.min.css';
import * as Sentry from '@sentry/nextjs';
import { GraphQLClient } from 'graphql-request';
import 'keen-slider/keen-slider.min.css';
import { getMessages } from 'lang';
import { IS_SERVER, eventNames } from 'mdc-constants';
import App, { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Hydrate, QueryClientProvider, dehydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  ConfigProvider,
  GraphQLClientProvider,
  GraphQLClientState,
  UserSetupStatusProvider,
  useConfig,
} from 'providers';
import { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useGetNavigation } from 'services/graphql/cms';
import { getQueryClient, queryClient } from 'services/queryClient';
import { browserEnv, getAppConfiguration } from '../utils/browserEnv';
import '../styles/globals.scss';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const inter = Inter({ subsets: ['latin'] });

const Main = ({ Component, pageProps }: AppProps<{ dehydratedState: unknown }>) => {
  const { googleAnalyticId, mdcGraphQlUrl, zohoSalesIqWidgetCode, auth0Audience, auth0ClientId, auth0Domain } =
    useConfig();

  const router = useRouter();

  const appGraphQLClient: GraphQLClientState = {
    graphQLClient: new GraphQLClient(mdcGraphQlUrl || ''),
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url, googleAnalyticId);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, googleAnalyticId]);

  const onRedirectCallback = (appState) => {
    event({
      action: eventNames.userLoggedIn,
      category: 'authentication',
      label: eventNames.userLoggedInLabel,
      value: appState?.targetUrl,
    });
    router.replace(appState?.targetUrl || '/');
  };

  const handleGlobalError = (error) => {
    Sentry.captureException(error);
  };

  const browserQueryClient = getQueryClient({ onError: handleGlobalError });
  const [reactQueryClient] = useState(browserQueryClient);

  const renderGA = useMemo<boolean>(
    () => !IS_SERVER && !!googleAnalyticId && !!googleAnalyticId.length,
    [googleAnalyticId],
  );

  const renderZohoSalesIq = useMemo<boolean>(
    () => !IS_SERVER && !!zohoSalesIqWidgetCode && !!zohoSalesIqWidgetCode.length,
    [zohoSalesIqWidgetCode],
  );

  const redirectUri = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.origin;
    }

    return undefined;
  }, []);

  return (
    <Theme fonts={inter.style.fontFamily} className={inter.className}>
      <Auth0Provider
        useRefreshTokens
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{
          redirect_uri: redirectUri,
          audience: auth0Audience!!,
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <UserSetupStatusProvider>
          <GraphQLClientProvider defaultState={appGraphQLClient}>
            <QueryClientProvider client={reactQueryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                {renderGA && (
                  <>
                    <Script
                      defer
                      strategy="afterInteractive"
                      src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticId}`}
                    />
                    <Script
                      defer
                      id="gtag-init"
                      strategy="afterInteractive"
                      dangerouslySetInnerHTML={{
                        __html: `
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', '${googleAnalyticId}', {
                            page_path: window.location.pathname,
                          });
                        `,
                      }}
                    />
                  </>
                )}
                {renderZohoSalesIq && (
                  <>
                    <Script
                      defer
                      type="text/javascript"
                      id="zsiqchat"
                      strategy="afterInteractive"
                      dangerouslySetInnerHTML={{
                        __html: `
                      var $zoho=$zoho || {};
                      $zoho.salesiq = $zoho.salesiq || {widgetcode: "bbcd816984288aa9e2a85d6124d8da8024393f42c716bc2f63a79bcade42c0e0", values:{},ready:function(){}};
                      var d=document;
                      s=d.createElement("script");
                      s.type="text/javascript";
                      s.id="zsiqscript";
                      s.defer=true;
                      s.src="https://salesiq.zoho.com/widget";
                      t=d.getElementsByTagName("script")[0];
                      t.parentNode.insertBefore(s,t);
                    `,
                      }}
                    />
                  </>
                )}
                <Component {...pageProps} />
              </Hydrate>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </GraphQLClientProvider>
        </UserSetupStatusProvider>
      </Auth0Provider>
    </Theme>
  );
};

const AppWithIntl = (props) => {
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const messages = getMessages(locale);

  return (
    <IntlProvider defaultLocale={defaultLocale} locale={locale || 'en'} messages={messages}>
      <Main {...props} />
    </IntlProvider>
  );
};

const AppWithConfigs = (props) => (
  <ConfigProvider values={getAppConfiguration()}>
    <AppWithIntl {...props} />
  </ConfigProvider>
);

AppWithConfigs.getInitialProps = async (appContext) => {
  await queryClient.prefetchQuery(
    ['getNavigation', { navigationIdOrSlug: '1' }],
    () => useGetNavigation.fetcher(cmsGraphQLClient, { navigationIdOrSlug: '1' })(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  await queryClient.prefetchQuery(
    ['getNavigationFooter', { navigationIdOrSlug: '2' }],
    () => useGetNavigation.fetcher(cmsGraphQLClient, { navigationIdOrSlug: '2' })(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  await App.getInitialProps(appContext);

  return {
    pageProps: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

const ProtectedApp = withPasswordProtect(AppWithConfigs, {
  loginApiUrl: '/api/login',
  checkApiUrl: '/api/passwordCheck',
});

export default browserEnv.NEXT_PUBLIC_PASSWORD_PROTECT ? ProtectedApp : AppWithConfigs;
