export const eventNames = {
  // Boats events
  boatEventCategory: 'boat_related_events',

  boatUpdate: 'user_boat_update',
  boatUpdateLabel: 'boat_update',

  boatDelete: 'user_boat_delete',
  boatDeleteLabel: 'boat_delete',

  boatAddManually: 'boat_created_manually',
  boatAddManuallyLabel: 'boat_created_manually',

  boatAddFromTBA: 'THE_BOAT_APP',
  boatAddFromMDCAccount: 'MDC_ACCOUNT',

  boatAddDb: 'boat_created_from_db',
  boatAddDbLabel: 'boat_create',

  boatShare: 'user_boat_share',
  boatShareLabel: 'boat_share',

  // Trips events
  tripEventCategory: 'trips_related_events',
  tripCreated: 'user_trip_create',
  tripCreatedLabel: 'trip_create',

  tripUpdate: 'user_trip_update',
  tripUpdateLabel: 'trip_update',

  tripEnded: 'user_trip_ended',
  tripEndedLabel: 'trip_ended',

  tripDeleted: 'user_trip_deleted',
  tripDeletedLabel: 'trip_deleted',

  allLogsListView: 'user_all_logs_list_view',
  allLogsListViewLabel: 'all_logs_list_view',

  logAddedToTrip: 'user_log_add_to_trip',
  logAddedToTripLabel: 'log_add_to_trip',

  logListPrinted: 'user_log_list_print',
  logListPrintedLabel: 'log_list_print',

  logUpdated: 'user_log_update',
  logUpdatedLabel: 'log_update',

  logDeleted: 'user_log_deleted',
  logDeletedLabel: 'log_deleted',

  // Inventory events
  inventoryEventLabel: 'inventory_related_events',
  inventoryCreated: 'user_inventory_created',
  inventoryCreatedLabel: 'inventory_created',

  inventoryUpdated: 'user_inventory_updated',
  inventoryUpdatedLabel: 'inventory_updated',

  inventoryDeleted: 'user_inventory_deleted',
  inventoryDeletedLabel: 'inventory_deleted',

  inventoryPrinted: 'user_inventory_printed',
  inventoryPrintedLabel: 'inventory_printed',

  inventoryListView: 'user_inventory_list_view',
  inventoryListViewLabel: 'inventory_list_view',

  // Task events
  taskEventLabel: 'task_related_events',
  taskCreated: 'user_task_created',
  taskCreatedLabel: 'task_created',

  taskUpdated: 'user_task_updated',
  taskUpdatedLabel: 'task_updated',

  taskDeleted: 'user_task_deleted',
  taskDeletedLabel: 'task_deleted',

  taskPrinted: 'user_task_printed',
  taskPrintedLabel: 'task_printed',

  taskListView: 'user_task_list_view',
  taskListViewLabel: 'task_list_view',

  // Checklist events
  checklistEventCategory: 'checklists_related_events',
  checklistCreated: 'user_checklist_create',
  checklistCreatedLabel: 'checklist_create',

  checklistUpdate: 'user_checklist_update',
  checklistUpdateLabel: 'checklist_update',

  checklistDeleted: 'user_checklist_deleted',
  checklistDeletedLabel: 'checklist_deleted',

  checklistView: 'user_checklist_view',
  checklistViewLabel: 'checklist_view',

  checklistCopied: 'user_checklist_copied',
  checklistCopiedLabel: 'checklist_copied',

  checklistFollowed: 'user_checklist_followed',
  checklistFollowedLabel: 'checklist_followed',

  checklistListView: 'user_checklist_list_view',
  checklistListViewLabel: 'checklist_list_view',

  // Alerts events
  alertEventCategory: 'alerts_related_events',
  alertView: 'user_alert_view',
  alertViewLabel: 'alert_view',

  alertListView: 'user_alert_list_view',
  alertListViewLabel: 'alert_list_view',

  alertPrint: 'user_alert_print',
  alertPrintLabel: 'alert_print',

  alertExport: 'user_alert_export',
  alertExportLabel: 'alert_export',

  // Files events
  fileEventCategory: 'files_related_events',
  fileView: 'user_file_view',
  fileViewLabel: 'file_view',

  fileListView: 'user_file_list_view',
  fileListViewLabel: 'file_list_view',

  fileUpload: 'user_file_upload',
  fileUploadLabel: 'file_upload',

  fileDownload: 'user_file_download',
  fileDownloadLabel: 'file_download',

  fileDelete: 'user_file_delete',
  fileDeleteLabel: 'file_delete',

  fileUpdate: 'user_file_update',
  fileUpdateLabel: 'file_update',

  // User events
  userEventCategory: 'user_related_events',

  userRegistered: 'user_registered',
  userRegisteredLabel: 'user_registered',

  userLoggedIn: 'user_logged_in',
  userLoggedInLabel: 'user_logged_in',

  userResetPassword: 'user_reset_password',
  userResetPasswordLabel: 'user_reset_password',

  userForgetPassword: 'user_forget_password',
  userForgetPasswordLabel: 'user_forget_password',

  userConfirmedEmail: 'user_confirmed_email',
  userConfirmedEmailLabel: 'user_confirmed_email',

  userUpdated: 'user_updated_profile',
  userUpdatedLabel: 'updated_profile',

  userActivityLogView: 'user_activity_log_view',
  userActivityLogViewLabel: 'user_activity_log_view',

  userActivityDetailsLogView: 'user_activity_log_details_view',
  userActivityLogDetailsViewLabel: 'user_activity_log_details_view',

  paymentSuccessfully: 'payment_successfully',
  paymentSuccessfullyLabel: 'Payment successfully',

  referralConverted: 'referral_converted',
  referralConvertedLabel: 'Referral converted',
};
