import { useContext } from 'react';
import { AntMessageContext } from '../AntMessageProvider';

export function useAntMessage() {
  const contextValue = useContext(AntMessageContext);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a AntNotificationProvider component');
  }

  return contextValue;
}
