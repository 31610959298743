import { captureException } from '@sentry/react';
import { GraphQLClient } from 'graphql-request';
import React, { createContext, useMemo } from 'react';
import { useConfig } from './hooks/useConfig';

export type GraphQLClientState = {
  graphQLClient: GraphQLClient;
};

type Props = {
  children: React.ReactNode;
  defaultState?: GraphQLClientState;
};

export type GraphQLClientProviderState = GraphQLClientState | null;

export const GraphQLClientContext = createContext<GraphQLClientProviderState>(null);

export const GraphQLClientProvider = ({ children, defaultState }: Props) => {
  const { mdcGraphQlUrl } = useConfig();

  const initialState: GraphQLClientState = useMemo(() => {
    if (!mdcGraphQlUrl) {
      const errorMessage = 'No mdcGraphQlUrl configured';

      captureException(errorMessage, {
        level: 'error',
        tags: {
          caller: 'GraphQLClientProvider',
        },
      });

      throw errorMessage;
    }

    return {
      graphQLClient: new GraphQLClient(mdcGraphQlUrl || ''),
    };
  }, [mdcGraphQlUrl]);

  return <GraphQLClientContext.Provider value={defaultState || initialState}>{children}</GraphQLClientContext.Provider>;
};
