import { createContext, FC } from 'react';
import { message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';

type TAntMessage = {
  message?: MessageInstance;
};

export const AntMessageContext = createContext<TAntMessage>({});

export const AntMessageProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [api, contextHolder] = message.useMessage();

  return (
    <AntMessageContext.Provider value={{ message: api }}>
      {contextHolder}
      {children}
    </AntMessageContext.Provider>
  );
};

export default AntMessageProvider;
