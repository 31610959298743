import { QueryClient, QueryCache, Query } from '@tanstack/react-query';

const defaultQueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: false,
      retry: 3,
      useErrorBoundary: false,
    },
  },
};

export const queryClient = new QueryClient({
  ...defaultQueryClientConfig,
  queryCache: new QueryCache({
    onError: (error) => {
      console.log('🚀 An error ocurred!');
    },
  }),
});

interface CacheConfig {
  onError?: (error: unknown, query: Query<unknown, unknown, unknown>) => void;
  onSuccess?: (data: unknown, query: Query<unknown, unknown, unknown>) => void;
}

export const getQueryClient = (cacheConfig?: CacheConfig) =>
  new QueryClient({
    ...defaultQueryClientConfig,
    queryCache: new QueryCache({
      ...cacheConfig,
    }),
  });
