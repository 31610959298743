declare global {
  interface Window {
    gtag: any;
    rewardful?: (type?: string, args?: any) => void;
    Rewardful?: any;
  }
}

// window.gtag = window.gtag || {};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, gaId: string) => {
  if (window && window.gtag) {
    window.gtag('config', gaId, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events

interface IEvent {
  action: string;
  category: string;
  label: string;
  value?: string;
}

export const event = ({ action, category, label, value }: IEvent) => {
  if (window && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};
