import { createContext, FC } from 'react';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

type TAntNotification = {
  notification?: NotificationInstance;
};

export const AntNotificationContext = createContext<TAntNotification>({});

export const AntNotificationProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <AntNotificationContext.Provider value={{ notification: api }}>
      {contextHolder}
      {children}
    </AntNotificationContext.Provider>
  );
};

export default AntNotificationProvider;
