import { MEGABYTE_IN_BYTES } from './size_in_bytes';

export const CUSTOM_FIELDS_ITEMS_NR_LIMIT = {
  free: 0,
  gold: 10,
};
export const STORAGE_SIZE_LIMIT = {
  free: 300,
  gold: 3072,
};

export const MAX_NUMBER_SUBSCRIPTION_LICENSES = 100;

export const MAX_UPLOAD_FILE_SIZE_IN_BYTES = MEGABYTE_IN_BYTES * 100;
