import { bool, envsafe, str } from 'envsafe';
import { ConfigType } from 'providers';

export const browserEnv = envsafe(
  {
    NEXT_PUBLIC_APP_CODE: str({
      devDefault: 'TBD-WEB',
      input: process.env.NEXT_PUBLIC_APP_CODE,
    }),
    NEXT_PUBLIC_CMS_API_HOST_URL: str({
      input: process.env.NEXT_PUBLIC_CMS_API_HOST_URL,
    }),
    NEXT_PUBLIC_GA_ID: str({
      input: process.env.NEXT_PUBLIC_GA_ID,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_MDC_API_HOST_URL: str({
      input: process.env.NEXT_PUBLIC_MDC_API_HOST_URL,
    }),
    NEXT_PUBLIC_PASSWORD_PROTECT: bool({
      input: process.env.NEXT_PUBLIC_PASSWORD_PROTECT,
    }),
    NEXT_PUBLIC_SENTRY_DSN: str({
      input: process.env.NEXT_PUBLIC_SENTRY_DSN,
    }),
    NEXT_PUBLIC_SENTRY_ENV: str({
      input: process.env.NEXT_PUBLIC_SENTRY_ENV,
    }),
    NEXT_PUBLIC_SENTRY_ENABLED: bool({
      input: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
    }),
    NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: str({
      input: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    }),
    NEXT_PUBLIC_THEBOATDB_URL: str({
      input: process.env.NEXT_PUBLIC_THEBOATDB_URL,
    }),
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: str({
      input: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    }),
    NEXT_PUBLIC_AUTH0_LOGOUT_RETURN_TO: str({
      devDefault: 'https://marinedatacloud.com?action=logout',
      input: process.env.NEXT_PUBLIC_AUTH0_LOGOUT_RETURN_TO,
    }),
    NEXT_PUBLIC_AUTH0_DOMAIN: str({
      input: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    }),
    NEXT_PUBLIC_AUTH0_AUDIENCE: str({
      input: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    }),
    NEXT_PUBLIC_AUTH0_CLIENT_ID: str({
      input: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    }),
    NEXT_PUBLIC_TBD_BLOG_URL: str({
      input: process.env.NEXT_PUBLIC_TBD_BLOG_URL,
    }),
    NEXT_PUBLIC_MEILI_SEARCH_API_KEY: str({
      input: process.env.NEXT_PUBLIC_MEILI_SEARCH_API_KEY,
    }),
    NEXT_PUBLIC_MEILI_SEARCH_HOST_URL: str({
      input: process.env.NEXT_PUBLIC_MEILI_SEARCH_HOST_URL,
    }),
    NEXT_PUBLIC_ZOHO_SALES_IQ_WIDGET_CODE: str({
      allowEmpty: true,
      input: process.env.NEXT_PUBLIC_ZOHO_SALES_IQ_WIDGET_CODE,
    }),
    NEXT_PUBLIC_MDC_LANDING_URL: str({
      input: process.env.NEXT_PUBLIC_MDC_LANDING_URL,
    }),
    NEXT_PUBLIC_THEBOATAPP_URL: str({
      input: process.env.NEXT_PUBLIC_THEBOATAPP_URL,
    }),
    NEXT_PUBLIC_THEBOATAPP_LANDING_URL: str({
      input: process.env.NEXT_PUBLIC_THEBOATAPP_LANDING_URL,
    }),
    NEXT_PUBLIC_MDC_ACCOUNT_URL: str({
      input: process.env.NEXT_PUBLIC_MDC_ACCOUNT_URL,
    }),
  },
  {
    // As we're using plain JS, it's useful to use strict mode which prevents accessing undefined props
    strict: true,
  },
);

export function getAppConfiguration(): ConfigType {
  return {
    googleMapApiKey: browserEnv.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    appCode: browserEnv.NEXT_PUBLIC_APP_CODE,
    googleAnalyticId: browserEnv.NEXT_PUBLIC_GA_ID,
    sentryDns: browserEnv.NEXT_PUBLIC_SENTRY_DSN,
    sentryEnv: browserEnv.NEXT_PUBLIC_SENTRY_ENV,
    sentryEnabled: browserEnv.NEXT_PUBLIC_SENTRY_ENABLED,
    sentryTracesSampleRate: browserEnv.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    mdcApiHost: browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL,
    mdcFullApiUrl: `${browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL}/api/v2/`,
    mdcGraphQlUrl: `${browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL}/graphql`,
    cmsFullApiUrl: `${browserEnv.NEXT_PUBLIC_CMS_API_HOST_URL}/api`,
    cmsGraphQlUrl: `${browserEnv.NEXT_PUBLIC_CMS_API_HOST_URL}/graphql`,
    theboatDbUrl: browserEnv.NEXT_PUBLIC_THEBOATDB_URL,
    auth0LogoutReturnTo: browserEnv.NEXT_PUBLIC_AUTH0_LOGOUT_RETURN_TO,
    auth0Domain: browserEnv.NEXT_PUBLIC_AUTH0_DOMAIN,
    auth0Audience: browserEnv.NEXT_PUBLIC_AUTH0_AUDIENCE,
    auth0ClientId: browserEnv.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    theboatDbBlogUrl: browserEnv.NEXT_PUBLIC_TBD_BLOG_URL,
    meiliSearchApiKey: browserEnv.NEXT_PUBLIC_MEILI_SEARCH_API_KEY,
    meiliSearchHostUrl: browserEnv.NEXT_PUBLIC_MEILI_SEARCH_HOST_URL,
    zohoSalesIqWidgetCode: browserEnv.NEXT_PUBLIC_ZOHO_SALES_IQ_WIDGET_CODE,
    mdcLandingUrl: browserEnv.NEXT_PUBLIC_MDC_LANDING_URL,
    theboatAppUrl: browserEnv.NEXT_PUBLIC_THEBOATAPP_URL,
    theboatLandingUrl: browserEnv.NEXT_PUBLIC_THEBOATAPP_LANDING_URL,
    mdcAccountUrl: browserEnv.NEXT_PUBLIC_MDC_ACCOUNT_URL,
  };
}
