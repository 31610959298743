import { GraphQLClient } from 'graphql-request';
import { ConfigType } from 'providers';

export const getCmsGraphQLClient = (config: ConfigType): GraphQLClient => {
  if (!config.cmsGraphQlUrl) {
    throw new Error(`⚠️ No API URL (cmsGraphQlUrl) setup. 
    Make sure you have created appConfiguration file`);
  }

  return new GraphQLClient(config.cmsGraphQlUrl);
};
