import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ANNOUNCEMENT_ALERTS_IDS } from 'mdc-constants';
interface AnnouncementsDismissState {
  dismissedAnnouncements?: string[];
  addToDismissedAnnouncements: (id: string) => void;
  removeExpiredIdsFromLocalStorage: (fetchedIds: (string | null | undefined)[] | undefined) => void;
}

const useDismissedAnnouncements = create<AnnouncementsDismissState>()(
  persist(
    (set, get) => ({
      dismissedAnnouncements: [],

      addToDismissedAnnouncements: (id) => {
        const isAnnouncementDismissed = get().dismissedAnnouncements?.some(
          (dismissedAnnouncement) => dismissedAnnouncement === id,
        );

        if (isAnnouncementDismissed) {
          return false;
        }

        set((state) => ({ dismissedAnnouncements: [...(state.dismissedAnnouncements || []), id] }));
      },
      removeExpiredIdsFromLocalStorage: (fetchedIds) => {
        const filteredIds = get().dismissedAnnouncements?.filter((announcementId) =>
          fetchedIds?.includes(announcementId),
        );

        set(() => ({ dismissedAnnouncements: filteredIds }));
      },
    }),

    {
      name: ANNOUNCEMENT_ALERTS_IDS,
    },
  ),
);

export const useDismissedAnnouncement = () => useDismissedAnnouncements((state) => state.dismissedAnnouncements);
export const useAddToDismissedAnnouncements = () =>
  useDismissedAnnouncements((state) => state.addToDismissedAnnouncements);
export const useRemoveExpiredIdsFromLocalStorage = () =>
  useDismissedAnnouncements((state) => state.removeExpiredIdsFromLocalStorage);
