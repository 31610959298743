import { useContext } from 'react';
import { SetupStatusContext } from '../UserSetupStatusProvider';

export const useUserSetupStatus = () => {
  const contextValue = useContext(SetupStatusContext);

  if (!contextValue) {
    throw new Error('Wrap your components tree with a SideBarStatusContext component');
  }

  return contextValue;
};
